import { dvh } from '@graphcommerce/framer-utils'
import { m, useIsPresent } from 'framer-motion'
import React, { useEffect } from 'react'
import type { PageItem } from '@graphcommerce/framer-next-pages/types'
import { useApolloClient, useQuery } from '@graphcommerce/graphql'
import { useAssignCurrentCartId, useCartIdCreate, useCartQuery, useCurrentCartId } from '@graphcommerce/magento-cart'
import { CustomerTokenDocument, useCustomerQuery, useCustomerSession } from '@graphcommerce/magento-customer'
import { useRouter } from 'next/router'
import { CurrentCartIdDocument } from '@graphcommerce/magento-cart/hooks/CurrentCartId.gql'
import { CartTemplatePageDocument } from 'graphql/CartTemplatePage.gql'
import { CustomerCartDocument } from '@graphcommerce/magento-cart/hooks/CustomerCart.gql'

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    inert?: 'true'
  }
}

export type PageProps = Pick<PageItem, 'routerKey'> & {
  active: boolean
  children: React.ReactNode
}

export function scrollPos(key: string): { x: number; y: number } {
  const scroll = global.window?.sessionStorage[`__next_scroll_${key}`] as string | undefined
  return scroll ? JSON.parse(scroll) : { x: 0, y: 0 }
}

export function Page(props: PageProps) {
  const { active, routerKey, children } = props
  const isPresent = useIsPresent()
  const client = useApolloClient();
  const assign = useAssignCurrentCartId();
  const { token } = useCustomerSession();
  const { currentCartId } = useCurrentCartId();
  const router = useRouter();
  const { data, loading } = useQuery(CustomerCartDocument, { fetchPolicy: 'no-cache' });

  function getCookie(key) {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  }

  function deleteCookie(key) {
    document.cookie = `${key}= ; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
  }

  useEffect(() => {
    const crt = getCookie('crt');
    if (!loading && data) {
      if (!crt) {
        assign(data?.customerCart?.id);
      }
    }
  }, [data, loading])

  useEffect(() => {
    const crt = getCookie('crt');
    const tkn = getCookie('tkn');
    if (tkn && tkn.length > 0) {
      client.writeQuery({
        query: CustomerTokenDocument,
        data: {
          customerToken: {
            __typename: "CustomerToken",
            token: tkn as string,
            createdAt: new Date().toString(),
            valid: true
          }
        }
      });
    }
    if (crt && crt.length > 0) {
      assign(crt as string);
    }
  }, [router]);

  useEffect(() => {
    const tkn = getCookie('tkn');
    if (tkn == token) {
      deleteCookie('tkn');
    }
  }, [token]);

  useEffect(() => {
    const crt = getCookie('crt');
    if (crt == currentCartId) {
      deleteCookie('crt');
    }
  }, [currentCartId]);

  /** The active Page doesn't get any special treatment */
  let top: number | undefined

  /** If the Page isn't active, we offset the page */
  if (!active) top = scrollPos(routerKey).y * -1

  /**
   * If the Page isn't present as a child of <AnimatePresence/>, but it is still present in the DOM,
   * we're navigating back, so we need to offset it.
   */
  if (!isPresent) top = scrollPos(routerKey).y

  const position = active && isPresent ? 'absolute' : 'fixed'
  const zIndex = active ? 1 : undefined

  return (
    <m.div
      layoutScroll
      style={{ position, top, zIndex, minHeight: dvh(100), left: 0, right: 0 }}
      inert={!active ? 'true' : undefined}
      data-nosnippet={!active ? true : undefined}
      aria-hidden={!active ? true : undefined}
    >
      {children}
    </m.div>
  )
}
