import { GlobalHead } from '@graphcommerce/magento-store'
import {
  CssAndFramerMotionProvider, DarkLightModeThemeProvider,
} from '@graphcommerce/next-ui'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { AppProps } from 'next/app'
import { ProductProvider } from '../components/ChipLabCustom/ProductContext/product-context'
import { TemplateProvider } from '../components/ChipLabCustom/TemplateContext/template-context'
import { lightTheme, darkTheme } from '../components/theme'
import { I18nProvider } from '../lib/i18n/I18nProvider'
import { PageLoadIndicator } from '../components/ChipLabCustom/PageLoad/PageLoadIndicator'
import { DesignProvider } from '../components/ChipLabCustom/DesignContext/DesignContext'
import { NewGraphQLProvider } from '../components/ChipLabCustom/Apollo'
import { FramerNextPages } from 'components/ChipLabCustom/Pages/Pages'
import { ErrorProvider } from 'components/ChipLabCustom/ErrorContext/ErrorContext'

export default function ThemedApp(props: AppProps) {
  const { router } = props
  const { locale = 'en' } = router

  return (
    <CssAndFramerMotionProvider>
      <I18nProvider key={locale} locale={locale}>
        <NewGraphQLProvider {...props}>
          <ThemeProvider theme={lightTheme}>
            <DesignProvider>
              <TemplateProvider>
                <ProductProvider>
                  <ErrorProvider>
                    <GlobalHead />
                    <CssBaseline />
                    <PageLoadIndicator />
                    <FramerNextPages {...props} />
                  </ErrorProvider>
                </ProductProvider>
              </TemplateProvider>
            </DesignProvider>
          </ThemeProvider>
        </NewGraphQLProvider>
      </I18nProvider>
    </CssAndFramerMotionProvider>
  )
}