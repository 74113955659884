import React, { useState } from 'react';

export const DesignContext = React.createContext<{
  params: any
  changeDesignsPerPage: React.Dispatch<React.SetStateAction<any>>
  setTotalPages: React.Dispatch<React.SetStateAction<any>>
  setParams: React.Dispatch<React.SetStateAction<any>>
}>({
  params: { page: 1, designsPerPage: 18, totalPages: 50 },
  changeDesignsPerPage: () => { },
  setTotalPages: () => { },
  setParams: () => { }
})

export const DesignProvider = (props) => {
  const [page, setPage] = useState(1);
  const [designsPerPage, setDesignsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(50);

  function setParams(params) {
    if (params.pageSize) {
      setDesignsPerPage(params.pageSize);
    }

    if (params.currentPage) {
      setPage(params.currentPage);
    }
  }

  return <DesignContext.Provider value={{
    params: {
      page: page,
      designsPerPage: designsPerPage,
      totalPages: totalPages,
    },
    changeDesignsPerPage: setDesignsPerPage,
    setTotalPages: setTotalPages,
    setParams: setParams,
  }}>{props.children}</DesignContext.Provider>;
}

export default DesignContext;