import React, { useState } from 'react';

export const ProductContext = React.createContext<{
  filters: any,
  changeFilters: any,
  resetFilters: any
}>({
  filters: {},
  changeFilters: () => { },
  resetFilters: () => { }
})

export const ProductProvider = (props) => {
  const [filters, setFilters] = useState({});

  function changeFilters(newFilters) {
    setFilters({ ...newFilters });
  }

  function resetFilters() {
    setFilters({});
  }

  return <ProductContext.Provider value={{
    filters: filters,
    changeFilters: changeFilters,
    resetFilters: resetFilters
  }}>{props.children}</ProductContext.Provider>;
}

export default ProductContext;