import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

export const TemplateContext = React.createContext<{
  params: any
  quantity: any
  chipValue: any
  categoryId: number
  search: any
  setParams: React.Dispatch<React.SetStateAction<any>>
  changeTemplatesPerPage: React.Dispatch<React.SetStateAction<any>>
  setTotalPages: React.Dispatch<React.SetStateAction<any>>
  setCategory: React.Dispatch<React.SetStateAction<any>>
  setQuantity: React.Dispatch<React.SetStateAction<any>>
  setChipValue: React.Dispatch<React.SetStateAction<any>>
  setSearch: React.Dispatch<React.SetStateAction<any>>
}>({
  params: { filters: {}, sort: {}, url: '', page: 1, templatesPerPage: 18, totalPages: 50 },
  quantity: 1,
  chipValue: 0.99,
  categoryId: 1,
  search: '',
  setParams: () => { },
  changeTemplatesPerPage: () => { },
  setTotalPages: () => { },
  setCategory: () => { },
  setQuantity: () => { },
  setChipValue: () => { },
  setSearch: () => { },
})

export const TemplateProvider = (props) => {
  const [page, setPage] = useState(1);
  const [templatesPerPage, setTemplatesPerPage] = useState(20);
  const [totalPages, setTotalPages] = useState(50);
  const [categoryId, setCategoryId] = useState(62);
  const [filters, setFilters] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [chipValue, setChipValue] = useState(0.99);
  const [search, setSearch] = useState('');
  const router = useRouter();

  useEffect(() => {
    changeSearch("");
  }, [router.asPath])

  function setParams(params) {
    if (params.pageSize) {
      setTemplatesPerPage(params.pageSize);
    }

    if (params.currentPage) {
      setPage(params.currentPage);
    }

    if (Object.keys(params.filters).length !== 0) {
      for (let key of Object.keys(params.filters)) {
        if (params?.filters[key]?.in?.length === 0) {
          delete params.filters[key];
        }
      }
      const newObj = { ...params.filters };
      delete newObj.template_name;
      setFilters({ ...newObj });
      setPage(1);
    }
  }

  function changeSearch(search) {
    setPage(1);
    setSearch(search);
  }

  return <TemplateContext.Provider value={{
    params: {
      page: page,
      templatesPerPage: templatesPerPage,
      totalPages: totalPages,
      filters: { category_id: { in: [categoryId.toString()] }, template_name: { like: search }, ...filters },
      url: '',
      sort: {},
    },
    categoryId: categoryId,
    changeTemplatesPerPage: setTemplatesPerPage,
    setTotalPages: setTotalPages,
    setCategory: setCategoryId,
    setParams: setParams,
    setQuantity: setQuantity,
    quantity: quantity,
    setChipValue: setChipValue,
    chipValue: chipValue,
    setSearch: changeSearch,
    search: search,
  }}>{props.children}</TemplateContext.Provider>;
}

export default TemplateContext;