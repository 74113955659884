import { ApolloError } from '@graphcommerce/graphql';
import React, { Dispatch, SetStateAction, useState } from 'react';

export const ErrorContext = React.createContext<{
  error: ApolloError | undefined
  setError: Dispatch<SetStateAction<ApolloError | undefined>>
}>({
  error: undefined,
  setError: () => { }
})

export const ErrorProvider = (props) => {
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  return <ErrorContext.Provider value={{
    error,
    setError
  }}>{props.children}</ErrorContext.Provider>;
}

export default ErrorContext;